<template lang="pug">
.onepager(v-scroll:throttle="{fn: onScroll, throttle: 500 }", :data-index="scrollIndex")
  //- HamburgerMenu
  .panel.teaser
    BubbleView(:hidden="showLost")
      h1
        Typewriter I thought dabbing was a dance move
  .panel.lost
    span.trigger(v-observe-visibility="{ callback: lostVisible, throttle: 100 }")
    Chaos(v-if="showLost || darken", :callback="setDarken")
    .inside(ref="absoluteContainer", :class="{ 'darken' : (darken && showLost), 'zoom': showContact }")
      h2
        ShowBox.heading(:hidden="!darken || !showLost", :delay ="darken ? 0: 3.2", :interval="darken ? 0.2: 0.6",
                :style="flashLightStyles")
          span L
          span.flashlight(ref="flicker" 
                          :class="{ 'flicker': (showLost || showContact), 'zoom': showContact }") o
          span s
          span t
        ShowBox.subtitle(:hidden="!showLost", :delay="darken ? 0.2: 2.6")
          span in 
          span Cannabis?
  .panel.reveal
    span.trigger(v-observe-visibility="{ callback: revealVisible, throttle: 100 }")
      | &nbsp;
    ShowBox(:hidden="!showContact" :delay="2")
      h1 yatra
      h2 Find your Path
      Contact(v-if="showForm")
      button.red(@click="addToCrm", v-if="!showForm") Learn More
    YatraLogo(v-if="showContact" :animateColor="true")
</template>
<style lang="scss" scoped>
@import '../assets/global';
.onepager {
  height: 100vh;
  overflow-x: hidden; // MOBILE-FIX-NEEDED
  scroll-snap-type: y mandatory; // SNAP-FIX-NEEDED
  &:after {
    content: '';
    background-image: url('../assets/texture.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.7;
    mix-blend-mode: multiply;
    z-index: 40;
    pointer-events: none;
  }
  @mixin panelSizing() {
    min-height: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .panel {
    @include panelSizing();
    position: relative;
    scroll-snap-align: start;
    background-color: $cream;
    .inside {
      position: fixed;
      @include panelSizing();
      z-index: 40;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
    }
    &.lost {
      .inside {
        z-index: 40;
        transition: background-color 0.4s ease-in-out;
        &.darken {
          background: $black;
        }
        &.zoom {
          background: $black;
          transition-delay: 0s;
          opacity: 0;
          transition: opacity 0.3s linear 0.7s;
        }
      }
          
      .darken {
        background: $black;
      }

      h2 {
        text-align: center;
        margin: 40px 0 0;

        .showbox.heading {
          transition: transform 0.7s cubic-bezier(.76,.54,.53,1.48);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

          display: flex;
          flex-direction: column;
          align-items: center;
          
          span {
            font-size: 100px;
            text-transform: uppercase;
            font-weight: 300;
            line-height: 125px;
            &.flashlight {
              color: transparent;
              transform: scale(1.1);
              filter: blur(2px);
              @keyframes flicker {
                0% {
                  opacity: 1;
                }
                55% {
                  opacity: 1;
                }
                58% {
                  opacity: 0;
                }
                61% {
                  opacity: 1;
                }
                80% {
                  opacity: 1;
                }
                84% {
                  opacity: 0;
                }
                86% {
                  opacity: 1;
                }
                88% {
                  opacity: 1;
                }
                90% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
              &.flicker {
                animation: 2s linear 0 flicker;
                animation-iteration-count: infinite;

              }
              $radius: 80px;
              @media (min-width: 800px) {
                $radius: 100px;
              }
              
              @media (min-width: 1020px) {
                $radius: 120px;
              }
              height: calc(2*$radius);
              width: calc(2*$radius);
              border-radius: $radius;
              background: $white;
              box-shadow: 0 0 10px $cream;
              transition: transform 0.4s ease-in;


              &.zoom {
                transform: scale(15);
                animation: none;
                opacity: 1;
                // animation-iteration-count: 2;
              }
            }
            // opacity: 0.9;
          }

          @media (min-width: 800px) {
            flex-direction: row;
            justify-content: space-around;

            span {
              font-size: 200px;
              line-height: 200px;
              &.flashlight {
                transform: scale(1.4);
              }
            }
          }
          @media (min-width: 1020px) {
            span {
              font-size: 360px;
              line-height: 380px;
            }
          }
        }

        .subtitle {
          color: $slate;
          padding-bottom: 40px;
          // display: inline-block;

          @media (min-width: 800px) {
            padding: 40px 0;
            font-size: 3rem;
            
          }
        }

        &:last-child {
          margin: 0 0 20px;
        }
      }
      .bottom {
        background: $beige;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.teaser {
      overflow-x: hidden;
      @media (min-width: 800px) {
        h2 {
          text-align: right;
          margin: 20px 40px 0;

          &:last-child {
            margin: 0 40px 20px;
          }
        }        
      }
    }
    &.reveal {
      .showbox {
        @media (min-width: 800px) {
          text-align: left;
          margin-left: 20px;
        }
        @media (min-width: 1020px) {
          margin-left: 100px;
        }
      }
    }
    @media (min-width: 980px) {
      padding: 10vh 3rem;
    }
  }

  @media (max-height: 500px) {
    // SPECIAL CASE
    // Phone in landscape mode
    .panel {
      &.teaser .bubble {
        margin: 0 auto;
      }
      & > * {
        zoom: 0.7;
      }
    }
  }
  .arrow {
    position: fixed;
    bottom: 20px;
    left: calc(50vw - 32px);
    width: 64px;
    height: 96px;
    background: $sand;
    z-index: 100;
    background: url('../assets/arrow.svg');

    @media (min-width: 800px) {
      left: 70vw;
    }
  }
  .yatraLogo {
    z-index: 50;
    mix-blend-mode: color-burn;
  }

  h1 {
    font-family: 'Butler';
    font-weight: 300;
    font-size: 84px;
    line-height: 84px;
    margin: 0;
    color: $darkGray;
  }
  h2 {
    margin: 0;
    font-family: 'Jost';
    font-weight: normal;
    font-size: 28px;
    color: $darkBrown;
    opacity: 0.7;
  }
  button, input[type='text'] {
    height: 54px;
    margin: 40px 0;
    min-width: 240px;
    max-width: 320px;
    display: inline-block;
    font-family: 'Jost';
    font-weight: 500;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    font-size: 20px;
    background: transparent;
    outline: none;
    // background: $sand;
    transition: 0.2s ease-in-out all;
    color: $black;
    border: solid 2px $black;
    &.shadow {
      box-shadow: 6px 6px 0 $black;
    }
    &.red {
      color: $sand;
      color: $terracotta;
      border: solid 3px $terracotta;
      &.shadow {
        box-shadow: 6px 6px 0 $terracotta;
      }
    }
    &.hero {
      line-height: 54px;
      vertical-align: middle;
      font-family: "Butler";
      font-weight: 700;
      letter-spacing: 0.1em;
      padding: 0 28px;
      min-width: auto;
    }

    &:hover, &:focus {
      background: $black;
      color: $cream;
      transform: translate3d(0, -4px, 0) scale3d(1.05, 1.05, 1);
      // transition-delay: none;
      &.red {
        background: $terracotta;
      }
    }   
    &:active {
        transform: none;
        transition: none;
      }
  }
}
</style>
<script>
// @ is an alias to /src
import YatraLogo from '@/components/YatraLogo.vue'
import HamburgerMenu from '@/components/Hamburger.vue'
import ShowBox from '@/components/ShowBox.vue'
import BubbleView from '@/components/Bubble.vue'
import Typewriter from 'typewriter-vue'
import Chaos from './Chaos.vue'
import Contact from './Contact.vue'

export default {
  name: 'OnePager',
  data() {
    return {
      showLost: false,
      showContact: false,
      showForm: false, 
      showPath: false,
      scrollTop: 0,
      darken: false
    }
  },
  components: {
    YatraLogo,
    ShowBox, 
    BubbleView, 
    HamburgerMenu,
    Typewriter,
    Chaos,
    Contact
  },
  computed: {
    contactStyles() {
      // zoomIndex needs to be between 0 & 1
      const { scrollIndex } = this;
      let zoomIndex = 0;
      if (scrollIndex > 1) {
        zoomIndex = Math.min(1.0, (scrollIndex - 1));  
      }
      return { 'transform' : ('scale(' + zoomIndex + ')') }
    },
    flashLightStyles() {
      return null;
      
      const { scrollTop } = this;
      let offsetY = window.innerHeight/2.0 - scrollTop + 120;
      offsetY = Math.max(offsetY, 0);
      offsetY = 'translate3d(0,' + offsetY + 'px, 0)';
      console.log("Y offset is " + offsetY);
      return { 'transform' : offsetY }
    },
    scrollIndex() {
      const { scrollTop } = this;
      const windowHeight = window.innerHeight;
      return scrollTop / windowHeight;
    }
  },
  methods: {
    onScroll(event, { scrollTop }) {
      this.scrollTop = scrollTop;
    },
    lostVisible(visible, { target }) {
      this.showLost = visible;
    },
    revealVisible(visible) {
      this.showContact = visible;
    },
    setDarken() {
      console.log("Set Darken called");
      this.darken = true;
    },
    addToCrm() {
      console.log('Add to CRM');
      this.showForm = true;
    }
  }
}
</script>
