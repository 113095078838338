<template lang="pug">
.thankyou
  //- HamburgerMenu
  .panel.reveal
    span.trigger(v-observe-visibility="{ callback: revealContent, throttle: 100 }")
      | &nbsp;
    ShowBox(:hidden="!showContent" :delay="1")
      h1 yatra
      h2 Stay Tuned
      p We'll get back to you soon!
      button(@click="navHome") Home
    YatraLogo(:animate-color="true")
</template>
<style lang="scss" scoped>
@import '../assets/global';
.thankyou {
  height: 100vh;
  overflow-x: hidden; // MOBILE-FIX-NEEDED
  scroll-snap-type: y mandatory; // SNAP-FIX-NEEDED
  &:after {
    content: '';
    background-image: url('../assets/texture.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.7;
    mix-blend-mode: multiply;
    z-index: 40;
    pointer-events: none;
  }
  @mixin panelSizing() {
    min-height: 100%;
    padding: 10vh 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .panel {
    @include panelSizing();
    position: relative;
    scroll-snap-align: start;
    background-color: $cream;
    @media (min-width: 980px) {
      padding: 10vh 3rem;
    }
  }

  @media (max-height: 500px) {
    // SPECIAL CASE
    // Phone in landscape mode
    .panel {
      &.teaser .bubble {
        margin: 0 auto;
      }
      & > * {
        zoom: 0.7;
      }
    }
  }
  .yatraLogo {
    z-index: 30;
    mix-blend-mode: color-burn;
  }

  h1 {
    font-family: 'Butler';
    font-weight: 300;
    font-size: 84px;
    line-height: 84px;
    margin: 0;
    color: $darkGray;
  }
  h2 {
    margin: 0;
    font-family: 'Jost';
    font-weight: normal;
    font-size: 28px;
    color: $darkBrown;
    opacity: 0.7;
  }
  button, input[type='text'] {
    height: 54px;
    margin: 40px 0;
    min-width: 240px;
    max-width: 320px;
    display: inline-block;
    font-family: 'Jost';
    font-weight: 500;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    font-size: 20px;
    background: transparent;
    outline: none;
    // background: $sand;
    transition: 0.2s ease-in-out all;
    color: $black;
    border: solid 2px $black;
    &.shadow {
      box-shadow: 6px 6px 0 $black;
    }

    &:hover, &:focus {
      background: $black;
      color: $cream;
      transform: translate3d(0, -4px, 0) scale3d(1.05, 1.05, 1);
      // transition-delay: none;
    }   
    &:active {
        transform: none;
        transition: none;
      }
  }
}
</style>
<script>
// @ is an alias to /src
import YatraLogo from '@/components/YatraLogo.vue'
import HamburgerMenu from '@/components/Hamburger.vue'
import ShowBox from '@/components/ShowBox.vue'

export default {
  name: 'ThankYou',
  data() {
    return {
      showContent: false
    }
  },
  components: {
    YatraLogo,
    ShowBox 
  },
  methods: {
    navHome() {
      console.log('Nav home');
      this.$router.push('/')
    },
    revealContent() {
      this.showContent = true;
    }
  }
}
</script>
  