<template lang="pug">
.bubble(:class="{ 'hidden' : hidden, 'animate' : !hidden }")
  img.background(src="@/assets/bubble-background.svg")
  img.foreground(src="@/assets/bubble-foreground.svg") 
  .center
    slot
</template>
<style lang="scss">
@import '../assets/global.scss';
@keyframes float {
  from {
    transform: translate3d(0,0,0);
  }
  25% {
    transform: translate3d(0,-8px,0);
  }
  50% {
    transform: translate3d(0,0,0);
  }
  75% {
    transform: translate3d(0,8px,0);
  }
  to {
    transform: translate3d(0,0,0);
  }        
}

@mixin quote-glyph() {
  content: "\201D";
  font-size: 400px;
  font-family: "Butler";
  position: absolute;
  top: -20px;
  right: 0px;
  color: $sand;
  position: fixed;
}

$bubbleHeight: 580px;
$bubbleWidth: 732px;


.bubble {

  &:before {
    @include quote-glyph();
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0.7s;
  }

  height: $bubbleHeight;
  min-width: 300px;
  width: 100vw;
  margin: 0 -1rem; // since $gutter: 20px;
  position: relative;
  max-width: $bubbleWidth;

  .background, .foreground, .center {
    position: absolute;
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
  }

  .background, .foreground {
    width: auto;
    height: auto;
    mix-blend-mode: multiply;
  }

  .background {
    z-index: 50;
    bottom: 0;
    left: calc(50% - $bubbleWidth/2);
  }

  .foreground {
    z-index: 51;
    top: 0;
    right: calc(50% - $bubbleWidth/2);
  }

  .center {
    z-index: 52;
    height: $bubbleHeight;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem; // gutter

    @media (min-width: 520px) {
      padding-left: 3rem;
    }

    h1 .content {
      text-align: left;
      color: $white;
      font-size: 72px;
      line-height: 72px;

      @media (min-width:660px) {
        padding: 0 60px 0 80px;
      }
    }
  }

  &.animate {
    transition: none;

    .background {
      animation: float 7s linear 1s infinite;
    }

    .center {
      animation: float 8s ease-in 1.5s infinite;
    }
  }

  &.hidden {
    &:before {
      opacity: 0;
      top: -200px; 
      transition-delay: 0s;
    }
    .background {
      opacity: 0;
      left: -50vw;
      // transform: translate3d(-100vw, 0, 0); // Doesnt work
    }
    .foreground {
      transform: translate3d(50vw, 0, 0);
      opacity: 0;
    }
    .center {
      transition-delay: 0;
      opacity: 0;
    }
  }

  @media (min-width: 800px) {
    max-width: 780px;
  }
}
</style>
<script>
export default {
  name: "BubbleView",
  props: {
    hidden: {
      type: Boolean, 
      default: false
    }
  }
}
</script>