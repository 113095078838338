import Vue from "vue";
import VueRouter from "vue-router";
import OnePager from "../views/OnePager.vue";
import ThankYou from "../views/ThankYou.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "onepager",
    component: OnePager
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  }, {
    path: "/staytuned",
    name: "staytuned",
    component: ThankYou
  // },
  // {
  //   path: "*",
  //   name: "404",
  //   component: SnapView
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
