<template lang="pug">
.chaos
  .words(:class="{ 'darken': darken } ")
    .word(v-for="word in shuffledWords" :style="randomStyle()") {{  word }}
</template>
<style lang="scss" scoped>
@import '../assets/global';
.chaos {
  .words {
    .word {
      position: absolute;
      transition: opacity 2s ease-in 4s;
    }
    &.darken {
      .word {
        opacity: 0;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
export default {
  name: 'Chaos',
  data () {
    return {
      darken: false,
      colors: ['#747470', '#4B3E3E', '#8F9A9C'],
      words: ["Vape", "Blunt", "Bowl", "Keef", "Topicals", "Cannabidiol", "Delta-9", "Spliff", "Chief", "Terpenes", "Indica-Dominant", "Trichomes", "Burn Down", "Skunk", "Infusion", "Land Race", "Full-spectrum", "Wax", "Shatter", "Moonrocks", "Caviar"],
      shuffledWords: [],
      interval: 250,
      isMobile: false
    }
  },
  props: {
    callback: {
      type: Function, 
      default: ()=>{}
    }

  },
  methods: {
    randomStyle() {
      const fontMin = 1.5
      const fontMax = this.isMobile ? 10 : 15;
      const increment = 0.1
      const fontSize = Math.floor(Math.random()*(fontMax - fontMin)/increment + fontMin)*increment + "rem"; 

      const leftPos = Math.floor(Math.random()*100 - 20) + "vw";
      const topPos = Math.floor(Math.random()*100 - 20) + "%";
      
      const rotation = "rotate(" 
                        + (Math.random() < 0.85 ? 0 : -90)
                        + "deg)";

      const font = Math.random() > 0.6 ? "Butler" : null;
      const fontStyle = Math.random() > 0.75 ? "italic" : null;

      const color = this.colors[Math.floor(Math.random() * 4)];

      return {
        color,
        fontSize,
        left: leftPos,
        top: topPos,
        transform: rotation,
        fontFamily: font, 
        fontStyle
      };
    },
    poppedItem(array) {
      const i= Math.floor(Math.random() * array.length);
      const item = array[i];
      array.splice(i, 1);
      return item;
    },
    presentItem(array) {
      if (this.words.length > 0) {
        const { words, shuffledWords } = this;
        shuffledWords.push(this.poppedItem(words))

        setTimeout(()=> {
          this.presentItem();
        }, this.interval)

        if (this.words.length === 3) {
          this.darken = true;
          this.callback();
        }
      }
    }
  },
  mounted() {
    this.isMobile = (screen.width <= 480);
    setTimeout(()=> {
      this.presentItem()
    }, this.interval)
  }
}
</script>