<template lang="pug">
//- .showbox(:class="{ hidden }", 
          v-observe-visibility="{ callback: visibilityChanged, throttle: 100 }")
.showbox(:class="{ hidden }")
  slot
</template>
<script>
  export default {
    name: "ShowBox",
    props: {
      hidden: {
        type: Boolean,
        default: true
      },
      delay: {
        type: Number, 
        default: 0.65
      },
      interval: {
        type: Number, 
        default: 0.2        
      }
    },
    methods: {
      applyDelays() {
        this.$slots.default.forEach(({ elm } , index) => {
          if (elm.style) {
            elm.style.transitionDelay = `${ this.delay + index * this.interval }s`;        
          }
        });      
      }
    },
    mounted() {
      this.applyDelays();
    },
    updated() {
      this.applyDelays();
    }
  };
</script>
<style scoped lang="scss">
@import '../assets/global.scss';
.showbox {
  transform: translate3d(0, 0, 0);
  & > * {
    transition: opacity 0.25s ease-in-out 1s, transform 0.25s ease-in-out 1s;
  }
  &.hidden {
    & > * {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: none;
    }
    & > .reveal {
      transform: translate3d(0, 80vh, 0);
    }
  }
}
</style>