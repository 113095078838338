<template lang="pug">
.hamburger(:class="{ isOpened }")
  button.icon(@click="isOpened = !isOpened")
  ShowBox.tray(:hidden="!isOpened",
              :delay="isOpened? 0.7 : 0.3"
              :interval="isOpened? 0.2 : 0")
    router-link(to="/", v-observe-visibility="{ callback: trayVisible, throttle: 100 }") 
      h3 Home
    router-link(to="/") 
      h3 Consumers
    router-link(to="/") 
      h3 Brands
    router-link(to="/") 
      h3 Retailers
    router-link(to="/about") 
      h3 About
</template>
<style lang="scss" scoped>
@import "../assets/global.scss";
.hamburger {
  .icon {
    height: 44px;
    width: 44px;
    text-align: center;
    z-index: 101;
    position: fixed;
    top: 1rem;
    left: 1rem;
    border-radius: 0;
    border: 0;
    background: $sand;
    box-shadow: 4px 4px 0 $darkGray;
    transition: 0.2s ease-in-out box-shadow;
  }
  .tray {
    position: fixed;
    z-index: 100;
    height: 100%;
    width: 100vw;
    // transform: translate3d(-100vw, 0, 0);
    margin-left: -100vw;
    transition: margin 0.2s ease-in-out;
    background: $beige;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 1rem;
    @media (min-width: 640px) {
      padding-left: 30vw;
    }
    @media (min-width: 980px) {
      padding-left: 40vw;
    }

    a, a:visited {
      font-family: "Butler";
      font-size: 40px;
      color: $slate;
      text-decoration: none;
      border-left: solid 5px transparent;
      transition: all 0.2s ease-in-out;
      // padding-left: 1rem;
      h3 {
        font-weight: 300;
        margin: 0;
        display: inline;
      }

      &:hover, &:focus {
        transition: none;
        color: $black;
        // border-color: $darkBrown;
      }
      &:active {
        transform: scale(0.97);
      }
    }

    h3 {
      // color:
    }
  }
  &.isOpened {
    .icon {
      box-shadow: 0 0 0 $darkGray;
    }
    .tray {
      // transform: translate3d(0,0,0);
      margin: 0;
    }
  }
} 
</style>
<script>
import ShowBox from '@/components/ShowBox.vue'
export default {
  name: "HamburgerMenu",
  components: {
    ShowBox
  },
  data() {
    return {
      isOpened: false
    }
  },
  methods: {
    trayVisible(visible) {
      this.isOpened = visible;
    }
  }
} 
</script>