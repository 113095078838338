<template>
<div class="contact">
  <form action="https://greenlit.us7.list-manage.com/subscribe/post?u=a34da4cc9072d758240d39240&amp;id=be6ce0c7e5&amp;f_id=00b0f7e4f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">
    <p>Yatra helps you explore the medical &amp; recreational benefits of cannabis at your own pace.</p>
    <p>Stay connected to find out how</p>
    <div id="mc_embed_signup_scroll">
      <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value="">
      <div id="mce-responses" class="clear foot">
        <div class="response" id="mce-error-response" style="display: none;"></div>
        <div class="response" id="mce-success-response" style="display: none;"></div>
      </div>
      <div aria-hidden="true" style="position: absolute; left: -5000px;">
          /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
          <input type="text" name="b_a34da4cc9072d758240d39240_be6ce0c7e5" tabindex="-1" value="">
      </div>
      <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe">
    </div>
  </form>
</div>
</template>
<style lang="scss">
@import '../assets/global';
form {
  font-size: 1.2rem;
  margin-top: 4rem;
  input {
    background: transparent;
    border: none;
    outline: none;
    border-bottom: solid 2px $slate;
    min-width: 240px;
    max-width: 320px;
    width: 100%;
    &:focus, &:hover {
      border-color: $terracotta;
    }
  }
  input[type="button"], input[type="submit"], button {
    height: 54px;
    margin: 40px 0;
    min-width: 240px;
    max-width: 320px;
    display: inline-block;
    font-family: 'Jost';
    font-weight: 500;
    letter-spacing: 0.18em;
    font-size: 20px;
    background: transparent;
    outline: none;
    // background: $sand;
    transition: 0.2s ease-in-out all;
    color: $black;
    border: solid 2px $black;

    &.red {
      color: $sand;
      color: $terracotta;
      border: solid 3px $terracotta;
    }

    &:hover, &:focus {
      background: $black;
      color: $cream;
      transform: translate3d(0, -4px, 0) scale3d(1.05, 1.05, 1);
      transition-delay: 0ms;
      &.red {
        background: $terracotta;
      }
    }

    &:active {
        transform: none;
        transition: none;
      }
  }
}
</style>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
<script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='USERTYPE';ftypes[5]='dropdown';fnames[6]='COMPANY';ftypes[6]='text';fnames[7]='MESSAGE';ftypes[7]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<script>
export default {
  name: 'Contact'
}
</script>